import { render, staticRenderFns } from "./Prices.vue?vue&type=template&id=212f11ac&scoped=true&"
import script from "./Prices.vue?vue&type=script&lang=js&"
export * from "./Prices.vue?vue&type=script&lang=js&"
import style0 from "./Prices.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./Prices.vue?vue&type=style&index=1&id=212f11ac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "212f11ac",
  null
  
)

export default component.exports